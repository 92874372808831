import imageCompression from "browser-image-compression";
import _ from "lodash";
import { parsePhoneNumber } from "react-phone-number-input";
import { MAX_MEGAPIXELS, MAX_FILE_SIZE } from "./constants";

export const constructUriWithSearchParams = (urlAsString, paramsToAdd) => {
  let urlAsObject = new URL(urlAsString);

  for (const [key, value] of Object.entries(paramsToAdd)) {
    urlAsObject.searchParams.append(key, value);
  }

  return urlAsObject.toString();
};

export const generateLinkErrorMessage = (linkError) => {
  return `The Axle service could not ${linkError} for the specified carrier.`;
};

export const generateManualErrorMessage = (manualError) => {
  return `The Axle service could not ${manualError} the uploaded document`;
};

export const checkZipCode = (str) => {
  // Check if the string consists of exactly 5 numeric characters
  return /^\d{5}$/.test(str);
};

export const getUserAndMetadataFromQueryParams = (obj, userKeys) => {
  const extractedUserItems = _.pick(obj, userKeys);
  const extractedMetadataKeys = _.difference(_.keys(obj), userKeys);
  const extractedMetadataItems = _.pick(obj, extractedMetadataKeys);

  // Parse phone number
  if (extractedUserItems.phone) {
    extractedUserItems.phone = parsePhoneNumber(
      extractedUserItems.phone,
      "US"
    )?.number;
  }

  // Return extracted user fields
  return {
    userQueryParams: extractedUserItems,
    metadataQueryParams: extractedMetadataItems,
  };
};

/**
 *
 * @param {*} file File object to compress
 * @param {*} mimeType Mime type of the image
 * @returns
 */
export const compressImage = async (file, mimeType) => {
  console.log(
    `Compressing image to max ${MAX_MEGAPIXELS} megapixels and up to ${MAX_FILE_SIZE} MB in size...`
  );
  try {
    // Calculate the original size in MB
    const sizeInMB = file.size / (1024 * 1024);
    console.log(`Original file size: ${sizeInMB.toFixed(2)} MB`);

    // Get the image dimensions
    const [img, canvas] = await imageCompression.drawFileInCanvas(file);
    const width = img.width;
    const height = img.height;
    const currentMegaPixels = (width * height) / 1000000;

    console.log(`Original megapixels: ${currentMegaPixels.toFixed(2)}`);

    // If image is already under both thresholds, return the original file
    if (currentMegaPixels <= MAX_MEGAPIXELS && sizeInMB <= MAX_FILE_SIZE) {
      console.log(
        "Image already meets size requirements. No compression needed."
      );
      return file;
    }

    // Calculate the scale factor only if we need to reduce megapixels
    const scaleFactor =
      currentMegaPixels > MAX_MEGAPIXELS
        ? Math.sqrt(MAX_MEGAPIXELS / currentMegaPixels)
        : 1;
    const targetWidth = Math.round(width * scaleFactor);
    const targetHeight = Math.round(height * scaleFactor);

    // Compression options
    const options = {
      maxSizeMB: MAX_FILE_SIZE,
      maxWidthOrHeight: Math.max(targetWidth, targetHeight),
      useWebWorker: true,
      fileType: mimeType,
    };

    // Compress the image
    const compressedFile = await imageCompression(file, options);

    // Calculate the compressed size in MB
    const compressedFileSizeInMB = compressedFile.size / (1024 * 1024);
    console.log(
      `Compressed file size: ${compressedFileSizeInMB.toFixed(2)} MB`
    );

    return compressedFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error;
  }
};
