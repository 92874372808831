import Info from "../icons/Info";
import Warning from "../icons/Warning";
import { IconSvg } from "./Icon";

const lookupTypeConfig = (type) => {
  switch (type) {
    case "warning":
      return {
        icon: Warning,
        color: "bg-amber-200 text-amber-800 fill-amber-800",
      };
    case "info":
      return {
        icon: Info,
        color: "bg-blue-200 text-blue-800 fill-blue-800",
      };
    default:
      return {
        icon: Info,
        color: "bg-blue-200 text-blue-800 fill-blue-800",
      };
  }
};

const Callout = ({ type, message }) => {
  const typeConfig = lookupTypeConfig(type);

  return (
    <div
      className={`flex gap-x-3 items-left rounded p-4 w-full ${typeConfig.color}`}
    >
      <IconSvg
        icon={typeConfig.icon}
        height={"h-4"}
        width={"w-4"}
        className={"pt-0.5"}
      />

      {message}
    </div>
  );
};

export default Callout;
