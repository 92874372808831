import { createElement } from "react";

const Icon = ({
  name,
  translate,
  color,
  className,
  h,
  w,
  onClick,
  ariaLabel,
  tabIndex,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        maskImage: `url("./icons/${name}.svg")`,
        WebkitMaskImage: `url("./icons/${name}.svg")`,
      }}
      className={`${className} ${h} ${w} ${color} ${translate} flex-none transform transition-all `}
      aria-label={ariaLabel}
      tabIndex={tabIndex ? tabIndex : ""}
    ></div>
  );
};

Icon.defaultProps = {
  h: "h-4",
  w: "w-4",
  translate: "translate-y-1",
  color: "bg-black",
};

/**
 * It takes in an icon, height, width, fill, and className, and returns an SVG icon with the given
 * height, width, fill, and className
 * @returns A React component that takes in props and returns an SVG icon.
 */
export const IconSvg = ({ icon, height, width, className, ...props }) => {
  return (
    <>
      {createElement(icon, {
        className: `${height ? height : "h-3"} ${
          width ? width : "w-3"
        } ${className}`,
        ...props,
      })}
    </>
  );
};

export default Icon;
