import _, { get } from "lodash";
import { useEffect, useRef, useState } from "react";
import { CARRIERS_WITH_ZIPCODE_LOGIN } from "../constants";

import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import CarrierLoader from "./CarrierLoader";

import Icon from "../components/Icon";
import { getAccount, login, updateIgnition } from "../lib/axle";
import { checkZipCode } from "../lib/utility";

const Login = ({
  step,
  nextStep,
  setAccountInfo,
  loginInformation,
  setLoginInformation,
  session,
  previousStep,
  setLinkError,
  posthog,
  loginAttempts,
  setLoginAttempts,
  setShowNav,
}) => {
  const handleUnsuccessfulGetAccount = (error) => {
    switch (error.code) {
      case 400:
        if (
          error.message ===
          "Sorry, your insurance account or policy has not been set up for online access."
        ) {
          nextStep("account-pending");
          return;
        }
      // If session expired, send customer to session-expired. Change 7/10/23 to back to carrier-error
      case 401:
      case 403:
        setLinkError(error.message);
        nextStep("carrier-error");
        return;
      // If 500 send customer to "failed"
      case 500:
        setLinkError("retrieve account details");
        nextStep("carrier-error");
        return;
      case 503:
        setLinkError(error.message);
        nextStep("carrier-maintenance");
        return;
      default:
        // Must return here to prevent getAccount from being called
        setError(error.message);
        setPromiseInProgress(false);
        return;
    }
  };

  const handleUnsuccessfulLogin = async (error) => {
    switch (error.message) {
      case "Username not found. Please try again":
      case "Username or password incorrect. Please try again":
        // Increment loginAttempt counter and display remaining attempts if incorrect username/password
        // Send to max login attempts if 3 or more unsuccessful attempts
        if (get(loginAttempts, carrier, 0) + 1 > 2) {
          nextStep("max-login-attempts");
          return;
        }

        // Increment login attempt counter
        setLoginAttempts({
          ...loginAttempts,
          [carrier]: get(loginAttempts, carrier, 0) + 1,
        });

        // Update error message
        error.message = `Username or password incorrect. You have ${
          3 - (get(loginAttempts, carrier, 0) + 1)
        } ${
          3 - (get(loginAttempts, carrier, 0) + 1) === 1
            ? "attempt"
            : "attempts"
        } remaining.`;
        setPromiseInProgress(false);
        setError(error.message);
        return;
      case "Sorry, your account is locked. Please reset your password and then try again!":
        setPromiseInProgress(false);
        setError(error.message);
        return;
      case "Sandbox only supports test credentials. Please try again.":
        setPromiseInProgress(false);
        setError("Sandbox only supports test credentials. Please try again.");
        return;
      case "Sorry, you are attempting to login with credentials to the wrong carrier. Please confirm the brand shown on your policy documents and visit the correct carrier login page.":
        setPromiseInProgress(false);
        setError(error.message);
        return;
      case "Sorry, your insurance account or policy has not been set up for online access.":
        nextStep("account-pending");
        return;
      case "Sorry, your AAA chapter is not supported by Axle.":
        nextStep("aaa-chapter-error");
        return;
      case "Sorry, this carrier is undergoing maintenance and is temporarily unavailable. Please try again later.":
        nextStep("carrier-maintenance");
        return;
      case "Session expired": // Session expired errors on login are errors which we believe can be solved by a retry
      default:
        // If an error occurs, and retry attempts are less than 1, retry logging in
        if (retryAttempts < 1) {
          retryAttempts++;
          loadingSteps = [
            "Login is taking longer than expected.",
            "Trying to contact your carrier again.",
            "Retrieving account information.",
          ];
          console.log("Received an error from login, retrying...");
          await loginGetAccount();
          // Else set the on-screen error message
        } else {
          setLinkError("login");
          nextStep("carrier-error");
        }
        return;
    }
  };

  const carrier = get(loginInformation, "carrier.id");

  const ignitionToken = session.id;
  // To test loader, uncomment below and comment out usePromisetracker
  // const promiseInProgress = true;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [promiseInProgress, setPromiseInProgress] = useState(false);

  let loadingSteps = [
    "Encrypting credentials",
    "Establishing a secure connection",
    "Contacting your carrier",
    "Retrieving account information",
  ];

  const registerUrl = get(loginInformation, "carrier.registerUrl", false);
  const recoveryUrl = get(loginInformation, "carrier.recoveryUrl", false);
  const isManualEnabled = get(session, "config.manual.enabled", false);

  const isLoginSupportEnabled = registerUrl || recoveryUrl || isManualEnabled;

  let retryAttempts = 0;

  // Form logic
  const loginGetAccount = async () => {
    let auth = null;

    // Remove the nav bar while loading
    setShowNav(false);

    let response;
    try {
      response = await login(
        ignitionToken,
        carrier,
        username,
        password,
        zipcode
      );
    } catch (error) {
      // Handle failed login
      console.log("Login did not return a 200 status code...");
      await handleUnsuccessfulLogin(error);

      // Restore the nav bar
      setShowNav(true);
      return;
    }

    // Handle successful login
    console.log("Login was successful, parsing response...");
    auth = get(response, "data");

    // If MFA required, send to MFA
    if (auth.mfaRequired) {
      // Set login result to link
      setLoginInformation({ ...loginInformation, result: "link" });

      nextStep("sendMfa");

      // Restore the nav bar
      setShowNav(true);
      return;
    }

    // If carrier is not found (edge case), send to manual input or unsupported carrier
    else if (auth.isNotFound) {
      // Set login result to unsupported-carrier
      setLoginInformation({
        ...loginInformation,
        result: "unsupported-carrier",
      });

      nextStep(isManualEnabled ? "manual-account" : "unsupported-carrier");

      // Restore the nav bar
      setShowNav(true);
      return;
    }

    // If carrier is part of basic flow, send to manual input or unsupported carrier
    else if (auth.isBasic) {
      // Set login result to basic
      setLoginInformation({ ...loginInformation, result: "basic" });

      nextStep(
        _(session).get("config.basic.manual") && isManualEnabled
          ? "manual-account"
          : "basic-policy-info"
      );

      // Restore the nav bar
      setShowNav(true);
      return;
    }

    try {
      // Fetch account overview
      const account = await getAccount(ignitionToken);

      // If no compatible policies on account send to "no-policies"
      if (_(account.policies).size() === 0) {
        nextStep("no-policies");
        return;
      }

      // Save account info to state
      setAccountInfo(account);

      // Set login result to link
      setLoginInformation({ ...loginInformation, result: "link" });
      // Go to account selection step
      nextStep("confirm");

      // Restore the nav bar
      setShowNav(true);
      return;
    } catch (error) {
      handleUnsuccessfulGetAccount(error);
      // Restore the nav bar
      setShowNav(true);
      return;
    }
  };

  const onSubmit = async (e) => {
    // Prevent default form behavior
    e.preventDefault();

    if (
      CARRIERS_WITH_ZIPCODE_LOGIN.includes(
        get(loginInformation, "carrier.id")
      ) &&
      !checkZipCode(zipcode)
    ) {
      setError("Please enter a valid zipcode!");
      return;
    }

    // Validate input requirements
    if (!username) {
      setError("Please add a username!");
      return;
    }

    if (!password) {
      setError("Please add a password!");
      return;
    }

    // Track login submit
    // Only track if a username and password are entered
    posthog.capture("login-submit", {
      step,
      carrier,
      loginAttempt: get(loginAttempts, carrier, 0) + 1,
    });

    setPromiseInProgress(true);
    await loginGetAccount();
  };

  // Setup login state
  useEffect(() => {
    posthog.capture("$pageview", { step, carrier });

    // Update ignition session with carrier
    const updateIgnitionLogin = async () => {
      await updateIgnition(session.id, {
        carrier,
      });
    };
    updateIgnitionLogin();

    // Get updated session
    const getLoginAttempts = async () => {
      // Reset login attempts if the user is coming from max attempts page
      if (previousStep === "max-login-attempts") {
        setLoginAttempts((prevAttempts) => ({
          ...prevAttempts,
          [carrier]: 0,
        }));
      } else {
        // Send to max login attempts if 3 or more unsuccessful attempts
        if (get(loginAttempts, carrier) > 2) {
          nextStep("max-login-attempts");
          return;
        }

        // Set remaining attempts error message if any unsuccessful attempts
        if (get(loginAttempts, carrier) > 0) {
          setError(
            `Username or password incorrect. You have ${
              3 - get(loginAttempts, carrier, 0)
            } ${
              3 - get(loginAttempts, carrier, 0) === 1 ? "attempt" : "attempts"
            } remaining.`
          );
        }

        // Update login attempts
        setLoginAttempts({
          ...loginAttempts,
          [carrier]: get(loginAttempts, carrier, 0),
        });
      }
    };
    getLoginAttempts();
  }, [posthog]);

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Logging in"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="grow flex flex-col gap-8">
            <div className="flex">
              <div className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"></div>
              <div
                style={{
                  backgroundImage: `url("${loginInformation.carrier.image}")`,
                  // backgroundColor: loginInformation.carrier.color,
                }}
                className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
                aria-label={loginInformation.carrier.name}
              ></div>
            </div>
            <div className="flex flex-col gap-y-2">
              <h3 className="text-xl text-black font-bold">
                Enter your login information
              </h3>
              <p className="text-lg text-black">
                Providing your <b>{loginInformation.carrier.name}</b> login
                information enables Axle to securely connect to your carrier.
              </p>
            </div>
            <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
              <div className="flex flex-col gap-y-6">
                {error && (
                  <div
                    className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                    role="status"
                  >
                    {" "}
                    {error}{" "}
                  </div>
                )}

                <input
                  placeholder="Username"
                  value={username}
                  className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                  type="text"
                  autocapitalize="off"
                  autocorrect="off"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className=" relative flex flex-row border border-solid border-black rounded-sm items-center">
                  <input
                    placeholder="Password"
                    value={password}
                    type={passwordVisible ? "text" : "password"}
                    autocapitalize="off"
                    autocorrect="off"
                    className=" flex-grow p-3 text-base text-black placeholder-black"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Icon
                    name={passwordVisible ? "hide" : "show"}
                    className={"mr-3 cursor-pointer"}
                    h={"h-5"}
                    w={"w-5"}
                    translate={"translate-y-0"}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    ariaLabel={
                      passwordVisible ? "Hide password" : "Show password"
                    }
                  />
                </div>
                {CARRIERS_WITH_ZIPCODE_LOGIN.includes(
                  get(loginInformation, "carrier.id")
                ) ? (
                  <input
                    placeholder="Policy zipcode"
                    value={zipcode}
                    className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                    type="text"
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                <ButtonPrimary
                  text={"Connect"}
                  width={"w-full"}
                  type={"submit"}
                />

                {isLoginSupportEnabled && (
                  <ButtonSecondary
                    onClick={() => nextStep("login-support")}
                    text={"I don’t know my login information"}
                  />
                )}
              </div>
            </form>{" "}
          </div>
        </>
      )}
    </>
  );
};

export default Login;
