import { chain, get, size, toLower } from "lodash";
import React, { useEffect } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/Button";

const combineAcceptedDocumentTypes = (session) => {
  const combinedAcceptedDocumentTypes = chain(session)
    .get("config.documentation.acceptedDocs") // Get accepted docs config
    .map("displayName") // Select display names
    .thru((names) => {
      const last = names.pop();
      // If there are exactly two items, join them with " or " without a comma
      return names.length === 1
        ? names[0] + " or " + last
        : names.length
        ? names.join(", ") + ", or " + last
        : last;
    })
    .value();

  return combinedAcceptedDocumentTypes; // Output: "Declarations Page or Policy Renewal, or ID Card"
};

const StepTransition = ({
  client,
  session,
  nextStep,
  step,
  previousStep,
  loginInformation,
  retryCount,
  setRetryCount,
  posthog,
  setLoginInformation,
  history,
  setHistory,
  partnerCarrierConfig,
}) => {
  const handleSessionExpiredSecondary = () => {
    return retryCount > 1
      ? {
          text: `${
            get(session, "config.manual.enabled") ? "Verify manually" : "Exit"
          }`,
          step: `${
            get(session, "config.manual.enabled") ? "manual-account" : "exit"
          }`,
        }
      : undefined;
  };

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
    });
    switch (step) {
      case "session-expired": {
        setRetryCount(retryCount + 1);
        return;
      }
      default:
        return;
    }
  });

  const isExitShortcut = get(
    session,
    "config.manual.exit-confirmation-shortcut",
    false
  );

  const pages = {
    "no-policies": {
      illustration: "not-found",
      header: "No policies found",
      subheader: "We didn’t find any compatible policies on your account.",
      primary: {
        text:
          get(session, "config.no-policies.primary.text") ??
          "Try a different account",
        step: "carriers",
      },
      secondary: {
        text: `${
          get(session, "config.no-policies.secondary.text") ??
          (get(session, "config.manual.enabled") ? "Verify manually" : "Exit")
        }`,
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "exit"
        }`,
      },
    },
    "carrier-maintenance": {
      illustration: "manual-required",
      header: "Please try again later",
      subheader: `${get(
        loginInformation,
        "carrier.name",
        "Your carrier"
      )}'s services are currently undergoing maintenance. We apologize for the inconvenience.`,
      primary: {
        text: "Continue",
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "exit"
        }`,
      },
      secondary: {
        text: "Try Again",
        step: previousStep === "enterMfa" ? "login" : previousStep,
      },
    },
    "session-expired": {
      illustration: "manual-required",
      header: "Hmm. Something’s not right.",
      subheader:
        "Your session has timed out. Please log in again to continue verification.",
      primary: {
        text: "Return to login",
        step: "login",
      },
      secondary: handleSessionExpiredSecondary(),
    },
    "exit-confirmation": {
      illustration: "exit-confirmation",
      header: "Are you sure?",
      subheader:
        get(session, "config.exit-confirmation.subheader") ??
        `${client.displayName} requires insurance verification to access its services. Declining verification through Axle may result in service delays.`,
      primary: {
        text:
          get(session, "config.exit-confirmation.primary.text") ??
          "Finish verification",
        step: previousStep,
      },
      secondary: {
        text: isExitShortcut
          ? "Upload manually"
          : get(session, "config.exit-confirmation.secondary.text") ?? "Exit",
        step: isExitShortcut
          ? "manual-account"
          : get(session, "config.exit-survey.enabled", true) // Defaults to true even if exit-survey config is not set
          ? "exit-survey"
          : "exit",
      },
      ...(isExitShortcut && {
        tertiary: {
          text:
            get(session, "config.exit-confirmation.secondary.text") ?? "Exit",
          step: get(session, "config.exit-survey.enabled", true) // Defaults to true even if exit-survey config is not set
            ? "exit-survey"
            : "exit",
        },
      }),
    },
    "max-login-attempts": {
      illustration: "max-login-attempts",
      header: "You have reached the maximum login attempts.",
      subheader: `To avoid locking or suspending your ${get(
        loginInformation,
        "carrier.name"
      )} account, please reset your login information before trying again.`,
      primary: {
        text: "I have reset my login information",
        step: "login",
      },
      secondary: {
        text: "Verify manually",
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "exit"
        }`,
      },
    },
    "aaa-chapter-error": {
      illustration: "not-found",
      header: "Hmm. Something’s not right.",
      subheader: "We're unable to connect to your AAA regional club right now.",
      primary: {
        text: `${
          get(session, "config.manual.enabled")
            ? "Verify manually"
            : "Try a different club or carrier"
        }`,
        step: `${
          get(session, "config.manual.enabled")
            ? "manual-account"
            : previousStep
        }`,
      },
      secondary: {
        text: "Exit",
        step: "exit",
      },
    },
    "account-pending": {
      illustration: "manual-required",
      header: "Hmm. Something’s not right.",
      subheader:
        "Sorry, your insurance account or policy has not been fully set up for online access. Please login to your account to finish setup.",
      primary: {
        text: "I've set up my insurance account",
        step: "login",
      },
      secondary: {
        text: `${
          get(session, "config.manual.enabled") ? "Verify manually" : "Exit"
        }`,
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "exit"
        }`,
      },
    },
    "policy-pending": {
      illustration: "manual-required",
      header: "Sorry, your policy is being processed or is missing data.",
      subheader: `Please ensure policy details are available in your account, and if not, contact ${get(
        loginInformation,
        "carrier.name"
      )}.`,
      secondary: {
        text: "Try again",
        step: "login",
      },
      primary: {
        text: `${
          get(session, "config.manual.enabled") ? "Verify manually" : "Exit"
        }`,
        step: `${
          get(session, "config.manual.enabled") ? "manual-account" : "exit"
        }`,
      },
    },
    "redirect-login": {
      illustration: "not-found",
      header: `This is a ${get(partnerCarrierConfig, "carrier.name")} policy`,
      subheader: `Click continue to finish logging in through ${get(
        partnerCarrierConfig,
        "carrier.name"
      )}`,
      primary: {
        text: "Continue",
        step: "login",
      },
    },
    "unsupported-document": {
      illustration: "not-found",
      header: "Hmm. Something’s not right.",
      subheader:
        "Please make sure to upload an image or pdf document with your policy information clearly legible.",
      primary: {
        text: "Try again",
        step: previousStep,
      },
      secondary: {
        text: "Exit",
        step: get(session, "config.exit-survey.enabled")
          ? "exit-survey"
          : "exit",
      },
    },
    "backup-document-required": {
      illustration: "not-found",
      header: "Policy document needed",
      subheader: `We couldn't retrieve all of the necessary details from your insurance carrier. Please upload your ${combineAcceptedDocumentTypes(
        session
      )} to finish sharing your information.`,
      primary: {
        text: "Upload document",
        step: "manual-policy-document",
      },
    },
  };

  return (
    <>
      <div className="flex flex-col grow gap-y-8 mt-16">
        <div className="flex justify-center">
          <div
            style={{
              backgroundImage: `url("./icons/${pages[step].illustration}.svg")`,
            }}
            className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
            aria-label={step}
          ></div>
        </div>
        <div className="flex flex-col justify-center gap-y-4 text-center text-black">
          <h3 className="font-bold text-xl"> {pages[step].header} </h3>
          <p className="text-xl"> {pages[step].subheader} </p>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mt-auto">
        {pages[step].primary && (
          <ButtonPrimary
            text={pages[step].primary.text}
            onClick={() => {
              if (step === "redirect-login") {
                // Take off the last two elements in history, so that carriers is the most recent step
                history.splice(size(history) - 2, 2);
                setHistory(history);
                // Set loginInformation to partner carrier
                setLoginInformation(partnerCarrierConfig);
              }
              nextStep(pages[step].primary.step);
            }}
            width={"w-full"}
          />
        )}
        {pages[step].secondary && (
          <ButtonSecondary
            onClick={() => nextStep(pages[step].secondary.step)}
            text={pages[step].secondary.text}
            {...(get(pages[step], "tertiary") && { outlined: true })}
          />
        )}
        {pages[step].tertiary && (
          <ButtonSecondary
            onClick={() => nextStep(pages[step].tertiary.step)}
            text={pages[step].tertiary.text}
          />
        )}
      </div>
    </>
  );
};

export default StepTransition;
