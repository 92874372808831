// Base
import { trackPromise } from "react-promise-tracker";
import "./App.css";

// Components
import Loader from "./components/Loader";
import Nav from "./components/Nav";

// Steps (special components)
import BasicPolicyInfo from "./steps/BasicPolicyInfo";
import Carriers from "./steps/Carriers";
import Confirm from "./steps/Confirm";
import Consent from "./steps/Consent";
import CreditCards from "./steps/CreditCards";
import EnterMfa from "./steps/EnterMfa";
import Error from "./steps/Error";
import Exit from "./steps/Exit";
import { ExitSurvey } from "./steps/ExitSurvey";
import Failed from "./steps/Failed";
import Interstitial from "./steps/Interstitial";
import Login from "./steps/Login";
import LoginSupport from "./steps/LoginSupport";
import ManualAccount from "./steps/ManualAccount";
import ManualCreditCard from "./steps/ManualCreditCard";
import ManualPolicyDocument from "./steps/ManualPolicyDocument";
import ManualPolicyInfo from "./steps/ManualPolicyInfo";
import Permissions from "./steps/Permissions";
import SendMfa from "./steps/SendMfa";
import StepTransition from "./steps/StepTransition";
import Success from "./steps/Success";
import UnsupportedCarrier from "./steps/UnsupportedCarrier";

// Library
import {
  getClient,
  getClientWithIgnitionConfig,
  getSession,
  updateIgnition,
} from "./lib/axle";
import { identifyPosthogUser } from "./lib/posthog";
import { getUserAndMetadataFromQueryParams } from "./lib/utility";

// External dependencies
import _, { get, size } from "lodash";
import { nanoid } from "nanoid";
import { usePostHog } from "posthog-js/react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import EnhancedManualPolicyDocument from "./steps/EnhancedManualPolicyDocument";

const USER_KEYS = ["id", "firstName", "lastName", "email", "phone"];

function App() {
  const [step, setStep] = useState("");
  const [history, setHistory] = useState([]);
  const [loginInformation, setLoginInformation] = useState({});
  const [accountInfo, setAccountInfo] = useState({});
  const [policyInfo, setPolicyInfo] = useState({});
  const [session, setSession] = useState({});
  const [client, setClient] = useState({});
  const [error, setError] = useState(null);
  const [linkError, setLinkError] = useState("retrieve information");
  const [manualError, setManualError] = useState(null);
  const [windowSize, setWindowSize] = useState(window.innerHeight);
  const [retryCount, setRetryCount] = useState(0);
  const [manualRetryCount, setManualRetryCount] = useState(0);
  const [carriers, setCarriers] = useState([]);
  const [partnerCarrierConfig, setPartnerCarrierConfig] = useState({});
  const [loginAttempts, setLoginAttempts] = useState({});
  const [showNav, setShowNav] = useState(true);

  // Initialize window event listeners
  window.addEventListener("resize", () => {
    setWindowSize(window.innerHeight);
  });

  // Set up promise tracker
  // NOTE: we use a delay here to debounce quick API calls
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });

  const nextStep = (nextStep) => {
    setStep(nextStep);
    setHistory([...history, step]);
  };

  const prevStep = () => {
    const previousStep = history.pop();
    setStep(previousStep);
    setHistory(history);
  };

  const setCarrier = (carrier) => {
    setLoginInformation({ ...loginInformation, ...carrier });
  };

  const posthog = usePostHog();

  useEffect(() => {
    // Setup session
    const setup = async () => {
      // Pull all query parameters from browser
      const parsedParams = queryString.parse(window.location.search);
      const { token, client, origin, ...restOfParams } = parsedParams;

      // Split rest of params by user and metadata
      const { userQueryParams, metadataQueryParams } =
        getUserAndMetadataFromQueryParams(restOfParams, USER_KEYS);

      // If no ignitionToken or client provided, throw error
      if (!token && !client) {
        setError("No token or client provided.");
        return;
      }

      try {
        let ignitionToken = token;

        // If no ignitionToken,  run set up for interstitial carriers, and then exit
        if (!token) {
          // Check if clientId is available
          if (!client) {
            setError("No client provided.");
            return;
          }

          const retrievedClient = await getClientWithIgnitionConfig(client);
          setClient(retrievedClient);

          setSession({
            user: userQueryParams,
            metadata: metadataQueryParams,
            origin,
          });
          setStep("interstitial");
          return;
        }

        // Retrieve session information, can optimize in the future to skip if createIgnition is called
        const retrievedSession = await getSession(ignitionToken);
        const retrievedClient = await getClient(retrievedSession.client);

        // If session status === "completed"
        if (retrievedSession.status === "completed") {
          setError(
            `Sorry, you cannot reuse a completed session. Please contact ${retrievedClient.displayName} to generate a new session.`
          );
          return;
        }

        // Set user and client information
        setClient(retrievedClient);
        setSession({ ...retrievedSession, origin });

        // Initialize Posthog user
        identifyPosthogUser(posthog, retrievedSession, retrievedClient);

        // Update Ignition with opened event
        await updateIgnition(retrievedSession.id, {
          status: "opened",
          lastEvent: {
            id: `event_${nanoid()}`,
            type: `ignition.opened`,
            data: {
              token: retrievedSession.id,
              user: retrievedSession.user,
              metadata: retrievedSession.metadata,
              client: retrievedClient.id,
            },
            createdAt: new Date().toISOString(),
          },
        });

        // Determine if firstStep is interstitial based on config
        const firstStep = _(retrievedSession).get("config.interstitial.enabled")
          ? "interstitial"
          : "consent";

        // Go to first step
        setStep(firstStep);
        return;
      } catch (error) {
        console.error(error);
        setError("Invalid or expired link.");
      }
    };
    trackPromise(setup());
  }, []);

  return (
    <div
      className={`w-full md:h-full flex justify-center items-center bg-gray-100`}
      style={{
        height: `${windowSize}px`,
        background: get(session, "config.styles.background", undefined),
      }}
    >
      <div className="w-full overflow-auto h-full md:w-4/6 lg:w-2/6 md:h-auto bg-white md:rounded-md md:shadow-md p-8 flex flex-col gap-8 relative md:max-h-[40rem] md:min-h-[40rem]">
        {!["interstitial"].includes(step) && (
          <Nav
            step={step}
            prevStep={prevStep}
            nextStep={nextStep}
            showPrevStep={
              size(history) &&
              ![
                "success",
                "unsupported-carrier",
                "consent",
                "interstitial",
              ].includes(step) &&
              showNav
            }
            showExit={
              ![
                "success",
                "exit",
                "exit-survey",
                "exit-confirmation",
                "unsupported-carrier",
                "interstitial",
              ].includes(step) && showNav
            }
          />
        )}
        <Loader isOpen={promiseInProgress} />
        {error ? (
          <div className="flex items-center gap-x-4 text-white text-sm rounded-sm bg-red-600 bg- p-3 -mb-1 z-20">
            {error}
          </div>
        ) : (
          (() => {
            switch (step) {
              case "interstitial":
                return (
                  <Interstitial
                    nextStep={nextStep}
                    session={session}
                    setSession={setSession}
                    client={client}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "consent":
                return (
                  <Consent
                    nextStep={nextStep}
                    step={step}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "permissions":
                return (
                  <Permissions
                    nextStep={nextStep}
                    step={step}
                    client={client}
                    posthog={posthog}
                    session={session}
                  />
                );
              case "credit-cards":
                return (
                  <CreditCards
                    step={step}
                    setLoginInformation={setLoginInformation}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                  />
                );
              case "carriers":
                return (
                  <Carriers
                    step={step}
                    nextStep={nextStep}
                    setCarrier={setCarrier}
                    session={session}
                    posthog={posthog}
                    setCarriers={setCarriers}
                  />
                );
              case "login":
                return (
                  <Login
                    step={step}
                    nextStep={nextStep}
                    setAccountInfo={setAccountInfo}
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    setLinkError={setLinkError}
                    posthog={posthog}
                    loginAttempts={loginAttempts}
                    setLoginAttempts={setLoginAttempts}
                    setShowNav={setShowNav}
                  />
                );
              case "login-support":
                return (
                  <LoginSupport
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    posthog={posthog}
                    setLoginInformation={setLoginInformation}
                    history={history}
                    setHistory={setHistory}
                  />
                );
              case "confirm":
                return (
                  <Confirm
                    step={step}
                    nextStep={nextStep}
                    accountInfo={accountInfo}
                    loginInformation={loginInformation}
                    carriers={carriers}
                    setPartnerCarrierConfig={setPartnerCarrierConfig}
                    session={session}
                    setPolicyInfo={setPolicyInfo}
                    setLinkError={setLinkError}
                    posthog={posthog}
                    setShowNav={setShowNav}
                  />
                );
              case "success":
                return (
                  <Success
                    accountInfo={accountInfo}
                    session={session}
                    client={client}
                    loginInformation={loginInformation}
                    policyInfo={policyInfo}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "sendMfa":
                return (
                  <SendMfa
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    session={session}
                    setLinkError={setLinkError}
                    posthog={posthog}
                    setShowNav={setShowNav}
                  />
                );
              case "enterMfa":
                return (
                  <EnterMfa
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    setAccountInfo={setAccountInfo}
                    session={session}
                    setLinkError={setLinkError}
                    posthog={posthog}
                    setShowNav={setShowNav}
                    client={client}
                  />
                );
              case "unsupported-carrier":
                return (
                  <UnsupportedCarrier
                    session={session}
                    client={client}
                    loginInformation={loginInformation}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "basic-policy-info":
                return (
                  <BasicPolicyInfo
                    loginInformation={loginInformation}
                    setAccountInfo={setAccountInfo}
                    setPolicyInfo={setPolicyInfo}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "manual-credit-card":
                return (
                  <ManualCreditCard
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    setAccountInfo={setAccountInfo}
                    setPolicyInfo={setPolicyInfo}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "manual-account":
                return (
                  <ManualAccount
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    setAccountInfo={setAccountInfo}
                    nextStep={nextStep}
                    session={session}
                    history={history}
                    setHistory={setHistory}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "manual-policy-info":
                return (
                  <ManualPolicyInfo
                    loginInformation={loginInformation}
                    accountInfo={accountInfo}
                    setPolicyInfo={setPolicyInfo}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "manual-policy-document":
                return (
                  <ManualPolicyDocument
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                    policyInfo={policyInfo}
                    setPolicyInfo={setPolicyInfo}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                    step={step}
                    setManualError={setManualError}
                    setShowNav={setShowNav}
                  />
                );
              case "enhanced-manual-policy-document":
                return (
                  <EnhancedManualPolicyDocument
                    loginInformation={loginInformation}
                    setLoginInformation={setLoginInformation}
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                    policyInfo={policyInfo}
                    setPolicyInfo={setPolicyInfo}
                    manualRetryCount={manualRetryCount}
                    setManualRetryCount={setManualRetryCount}
                    nextStep={nextStep}
                    session={session}
                    posthog={posthog}
                    step={step}
                    setManualError={setManualError}
                    setShowNav={setShowNav}
                  />
                );
              case "no-policies":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "exit-confirmation":
                return (
                  <StepTransition
                    step={step}
                    session={session}
                    client={client}
                    loginInformation={loginInformation}
                    nextStep={nextStep}
                    previousStep={history[size(history) - 1]}
                    posthog={posthog}
                  />
                );
              case "exit":
                return (
                  <Exit
                    session={session}
                    client={client}
                    previousStep={history[size(history) - 1]}
                    history={history}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "exit-survey":
                return (
                  <ExitSurvey
                    session={session}
                    step={step}
                    nextStep={nextStep}
                    posthog={posthog}
                  />
                );
              case "failed":
                return (
                  <Failed
                    session={session}
                    client={client}
                    previousStep={history[size(history) - 1]}
                    linkError={linkError}
                    manualError={manualError}
                    posthog={posthog}
                    step={step}
                  />
                );
              case "carrier-error":
                return (
                  <Error
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    linkError={linkError}
                    posthog={posthog}
                  />
                );
              case "manual-document-error":
                return (
                  <Error
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    manualError={manualError}
                    posthog={posthog}
                  />
                );
              case "unsupported-document":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "backup-document-required":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "carrier-maintenance":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "aaa-chapter-error":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "account-pending":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "policy-pending":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    posthog={posthog}
                  />
                );
              case "session-expired":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    previousStep={history[size(history) - 1]}
                    client={client}
                    retryCount={retryCount}
                    setRetryCount={setRetryCount}
                    posthog={posthog}
                  />
                );
              case "max-login-attempts":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    client={client}
                    previousStep={history[size(history) - 1]}
                    posthog={posthog}
                  />
                );
              case "redirect-login":
                return (
                  <StepTransition
                    step={step}
                    nextStep={nextStep}
                    loginInformation={loginInformation}
                    session={session}
                    client={client}
                    previousStep={history[size(history) - 1]}
                    posthog={posthog}
                    setLoginInformation={setLoginInformation}
                    history={history}
                    setHistory={setHistory}
                    partnerCarrierConfig={partnerCarrierConfig}
                  />
                );
              default:
                return;
              // do nothing
            }
          })()
        )}
        {}
      </div>
    </div>
  );
}

export default App;
