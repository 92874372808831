import { chain, get, isEmpty, upperFirst } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { usePromiseTracker } from "react-promise-tracker";
import { getDocumentDisplayText } from "../lib/constants";
import CarrierLoader from "../steps/CarrierLoader";
import { ButtonPrimary } from "./Button";

const combineAcceptedTypesString = (config) => {
  let combinedAcceptedTypesAsString = "Documents";

  const acceptedTypes = get(config, "documentation.acceptedTypes", false);
  if (!isEmpty(acceptedTypes)) {
    combinedAcceptedTypesAsString = chain(config)
      .get("documentation.acceptedTypes")
      .map((type) => {
        if (type === "pdf") return "PDFs"; // special case PDF to handle capitalization
        return type + "s"; // Pluralize
      })
      .thru((types) => {
        const last = types.pop();
        // If there are exactly two items, join them with " or " without a comma
        return types.length === 1
          ? types[0] + " or " + last
          : types.length
          ? types.join(", ") + ", or " + last
          : last;
      })
      .value();
  }

  return combinedAcceptedTypesAsString;
};

const ManualDropzone = ({
  session,
  loginInformation,
  uploadFile,
  loadingSteps,
  setFileData,
  setFilePath,
  setHasFile,
  documentStatus,
}) => {
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#000000",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusStyle = {
    borderColor: "#000000",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDropAccepted = useCallback((addedFiles) => {
    setHasFile(true);
    const reader = new FileReader();
    reader.onload = () => {
      setFileData(reader.result);

      setFilePath(addedFiles[0].path);
    };
    reader.readAsArrayBuffer(addedFiles[0]);
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    // Process rejected files
    if (rejectedFiles.length > 0) {
      switch (rejectedFiles[0].errors[0].code) {
        case "file-invalid-type":
          alert("Invalid file type. Please upload a supported file type.");
          break;
        case "file-too-large":
          alert("File is too large. Please upload a file smaller than 20MB.");
          break;
        case "file-too-small":
          alert(
            "File is not valid. Please upload a valid insurance policy document."
          );
          break;
        default:
          alert("An error occurred. Please try again.");
      }
    }
  }, []);

  let acceptedTypes = {
    "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    "application/pdf": [".pdf"],
  };

  // Remove application/pdf if not in acceptedTypes
  get(session, "config.documentation.acceptedTypes") &&
    !get(session, "config.documentation.acceptedTypes").includes("pdf") &&
    delete acceptedTypes["application/pdf"];

  // Remove image/* if not in acceptedTypes
  get(session, "config.documentation.acceptedTypes") &&
    !get(session, "config.documentation.acceptedTypes").includes("image") &&
    delete acceptedTypes["image/*"];

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: acceptedTypes,
    maxSize: 20960000, // 20ish MB
    minSize: 100, // 100 Bytes
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isFocused ? focusStyle : {}),
    }),
    [isDragAccept, isDragReject, isFocused]
  );

  const hasCarrier = get(loginInformation, "carrier.id") ? true : false;
  const files = acceptedFiles.map((file) => <p>{file.path}</p>);
  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Upload policy document"
          loadingSteps={loadingSteps}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${
                  hasCarrier
                    ? get(loginInformation, "carrier.image")
                    : `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
                }")`,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
              aria-label={
                hasCarrier
                  ? get(loginInformation, "carrier.name")
                  : "Unknown carrier"
              }
            ></div>
          </div>
          <div className="flex flex-col gap-y-2">
            <h3 className="text-xl text-black font-bold">
              Provide documentation of your policy
            </h3>
            <p className="text-base text-black">
              {upperFirst(
                `${combineAcceptedTypesString(
                  get(session, "config")
                )} with clearly readable text provide the best results.`
              )}
            </p>
          </div>

          <div
            {...getRootProps({
              style,
            })}
          >
            {documentStatus && documentStatus !== "success" && (
              <div
                className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                role="status"
              >
                {" "}
                {documentStatus}{" "}
              </div>
            )}
            <input {...getInputProps()} />
            {isDragAccept && <p>Release to upload file.</p>}
            {isDragReject && (
              <p>Sorry, you did not select a supported file type.</p>
            )}
            {!isDragActive && (
              <>
                <div className="text-sm mb-5">Select a supported file</div>
                <div className="text-sm font-bold">Accepted documents</div>
                {getDocumentDisplayText(session)}
              </>
            )}
            <div className="text-sm text-black">
              <br></br>
              {files}
            </div>
          </div>
          <div className="flex flex-col gap-y-6 mt-auto">
            <hr className="-mx-8 border-gray-100" />
            {/* <p className="text-center text-gray-500 text-xs">
          View our Glossary for more information
        </p> */}
            <ButtonPrimary
              text={"Continue"}
              onClick={uploadFile}
              width={"w-full"}
            />
          </div>
        </>
      )}
    </>
  );
};

export { ManualDropzone };
